@import "../Variables.scss";
.flex-row-container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    .box {
        flex:1;
        // overflow: hidden;

    }
    &.flex-right {
        justify-content: flex-end;
    }

}

.flex-column-container {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    .box {
       
        flex:1;
        position: relative;
        z-index: 10;
        // overflow: hidden;
    }
}


.overflow-y {overflow-y: auto;}
.overflow-x {overflow-x: auto;}




@media (max-width: 900px) {

    .flex-column-container, .flex-row-container {
        display: block;
    }
  }
