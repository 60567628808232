@import "../Variables.scss";
tr.mat-row:hover {background: rgba(0,0,0,0.03)}
.mat-form-field-appearance-legacy .mat-form-field-underline {background-color: silver !important;} 
select, select * {font-family: Unimasters !important; font-weight: 500 !important;}
.mat-dialog-container {border-radius: 20px !important;}
.mat-form-field-appearance-outline .mat-form-field-outline, .mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline{border:solid 1px #c0c0c0 !important}

.add-item .mat-form-field-appearance-outline .mat-form-field-outline, .add-item  .mat-form-field-appearance-outline.mat-form-field-invalid .mat-form-field-outline {border:none !important; box-shadow: none !important;}

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {background-color: $primary !important;}


header .mat-menu-panel {width: 200px !important; min-width: inherit !important; max-width: inherit !important;}

.send-message .mat-form-field-appearance-outline .mat-form-field-outline {border:none !important; box-shadow: none !important; border-top:solid 1px #e7e7e7 !important; border-radius: 0;}

.send-message .mat-form-field-wrapper {padding-bottom: 0 !important;}
