@import "../Variables.scss";

.row {
  margin-bottom: 10px;

  &.flex-row {
    display: flex;
  }

  &:after {
    display: table;
    clear: both;
    content: "";
  }

  *[class*="col-"] {
    float: left;
    margin-left: $grid-gutter;

    &:first-child {
      margin-left: 0;
    }

    &.flex-col {
      float: none !important;
      flex-direction: row;
    }
  }
  $cols: 5, 10, 15, 20, 25, 30, 100/3, 35, 40, 50, 60, 100 * (2/3), 70, 75, 80,
    85, 90, 95, 100;
  @each $i in $cols {
    $className: floor($i);
    $n: 100 / $i;
    $n-1: $n - 1;

    .col-#{$className} {
      width: #{(100% - $grid-gutter * $n-1) / $n};
    }
  }

  &.cols-split {
    $cols: 5, 10, 15, 20, 25, 30, 100/3, 35, 40, 50, 60, 100 * (2/3), 70, 75, 80,
      85, 90, 95, 100;
    @each $i in $cols {
      $className: floor($i);
      $n: 100 / $i;
      $n-1: $n - 1;

      .col-#{$className} {
        width: #{(100%) / $n};
        margin-left: 0;
      }
    }
  }

  &.end {
    margin-bottom: 0;
  }
}



@media (max-width: 900px) {
  .row {

    &.flex-row {
      display: block;
    }
  *[class*="col-"] {
    float: none;
    width: 100%;
    clear: left;
    margin-left: 0 !important;
  }
}
}
