@import "../Variables.scss";

.wraper {
    max-width: 1680px;
    width: 80%;
    min-width: 900px;
    margin: auto;
    position: relative;
}
@-webkit-keyframes pop-open {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

@keyframes pop-open {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    50% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}

@-webkit-keyframes fadeinout {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeinout {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes load {
    0% {
        margin-top: -100px;
        opacity: 0;
    }
    50% {
        margin-top: 1px;
        opacity: 1;
    }
}

@keyframes load {
    0% {
        margin-top: -100px;
        opacity: 0;
    }
    50% {
        margin-top: 1px;
        opacity: 1;
    }
}



* {
    &.load {
        animation: load 2s;
        -webkit-animation: load 2s;
    }
    &.fade {
        -webkit-animation: fadeinout 0.3s ease-in 1 forwards;
        animation: fadeinout 0.3s ease-in 1 forwards;
    }
}


@media (max-width: 900px) {
   .wraper {width: 100%; max-width: none;  min-width: inherit; padding-left: 10px; padding-right: 10px;}
   .name {display: none;}

}