@for $i from 1 through 200 {
  .w#{$i*5} {
    width: #{$i * 5}px;
  }
}
@for $i from 1 through 200 {
  .maxw#{$i*5} {
    max-width: #{$i * 5}px;
  }
}
@for $s from 1 through 200 {
  .h#{$s*5} {
    height: #{$s * 5}px;
  }
}
@for $s from 1 through 200 {
  .maxh#{$s*5} {
    max-height: #{$s * 5}px;
  }
}

@for $w from 1 through 20 {
  .w#{$w*5}p {
    width: #{$w * 5%};
  }
}
@for $i from 1 through 200 {
  .maxw#{$i*5}p {
    max-width: #{$i * 5%};
  }
}
@for $h from 1 through 20 {
  .h#{$h*5}p {
    height: #{$h * 5%};
  }
}
@for $s from 1 through 200 {
  .maxh#{$s*5}p {
    max-height: #{$s * 5%};
  }
}


