// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
// Plus imports for other components in your app.
@import "./app/scss/humge-ui-framework.scss";
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$marketplace-client-primary: mat.define-palette(mat.$indigo-palette);
$marketplace-client-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$marketplace-client-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$marketplace-client-theme: mat.define-light-theme(
  (
    color: (
      primary: $marketplace-client-primary,
      accent: $marketplace-client-accent,
      warn: $marketplace-client-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($marketplace-client-theme);

/* You can add global styles to this file, and also import other style files */


ul {
  list-style: none;
}

.mat-form-field-outline {
  background: #fff !important ;
  border-radius: 5px;
  box-shadow: $shadow;
}

.mat-form-field.mat-focused .mat-form-field-outline  {border: inherit;  outline: none !important;}

.mat-form-field-flex:hover .mat-form-field-outline {
  opacity: 0.5;
  transition: none !important;
  border:none
}
.mat-form-field-appearance-outline .mat-form-field-outline-start, .mat-form-field-appearance-outline .mat-form-field-outline-end, .mat-form-field-appearance-outline .mat-form-field-outline-gap {border:none !important}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label, .mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {display: inline-block !important;
  width: auto;background-color: #fff;box-shadow: $shadow;padding-left: 5px; padding-right: 5px; padding-top: 2px; padding-bottom: 2px; border-radius: 4px;}
.mat-dialog-container {
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ebf3fa+100 */
background: #ffffff; /* Old browsers */
background: -moz-linear-gradient(top,  #ffffff 0%, #ebf3fa 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #ffffff 0%,#ebf3fa 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #ffffff 0%,#ebf3fa 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ebf3fa',GradientType=0 ); /* IE6-9 */


  }
  .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon {color:#699DEE;}

  .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {border-color: $primary;}

  .mat-radio-button.mat-accent .mat-radio-inner-circle {background-color: $primary;}
  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20px;
    height: 20px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 2px solid #ffffff;
    border-top-color: #000000;
    animation: spinner .8s linear infinite;
  }

  .mat-button-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }

  .pre-line {
    white-space: pre-line;
  }

.mat-progress-bar {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 301 !important;
}
