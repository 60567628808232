@import "../Variables.scss";

.thin {
  font-weight: 100;
}

.light {
  font-weight: 300;

}

.normal {
  font-weight: 500 !important;
}

.bold {
  font-weight: 700 !important;
}

.allcaps {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.nodecor {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}
$s: 9;
$f: 1;
@for $s from 0 through 9 {
  .font#{$f}#{$s} {
    font-size: $fontsize + ($s/10);
  }
  $s: $s - 1;
}
$s: 9;
$f: 2;
@for $s from 0 through 9 {
  .font#{$f}#{$s} {
    font-size: ($f * $fontsize) + ($s/10);
  }
  $s: $s - 1;
}
$s: 9;
$f: 3;
@for $s from 0 through 9 {
  .font#{$f}#{$s} {
    font-size: ($f * $fontsize) + ($s/10);
  }
  $s: $s - 1;
}
@for $lh from 1 through 50 {
  .lh#{$lh*5} {
    line-height: #{$lh * 5}px;
  }
}

h1,h2,h3,h4,h5,h6 {font-family: $project-font-family;}
h1 {font-size: 1.4em; font-family: inherit; font-weight: 500; color: $h1_color;}
h2 {color: #000; font-size: 1.2em; font-family: inherit;}

.cmspage {
  h2,h3,h4,h5 {
    margin-bottom: 15px;

  }
  line-height: 1.5;
  p,ul {
    margin-bottom: 1em;
    
  }
  ul {
    list-style: disc;
    padding-left: 20px;
  }
}
