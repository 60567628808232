@import "../Variables.scss";
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding-top: 0.7em;
    padding-bottom: 0.7em;
    box-shadow: $shadow;
    z-index: 300;
    background-color: #fff;
    mat-icon {
        vertical-align: middle;
        margin-right: 3px;
        color: #699dee;
    }
    .logo-wrapper {
        width: 250px;
        * {
            vertical-align: middle;
        }
    }

    .logo-name {
        margin-left: 20px;
        border-left: solid 1px #cacaca;
        padding-left: 10px;
        line-height: 60px;

        mat-icon {
            color: $primary;
            vertical-align: sub !important;
            margin-top: 7px;
        }
    }

    .user {
        position: absolute;
        right: 0;
        top: 10px;
        a {
            text-decoration: none;
            font-weight: 500;
        }

        .primary {
            color: #fff;
        }
        button {
            border-radius: 20px;
            mat-icon {
                color: #fff;
            }
        }
        .outlined {
            border: solid 1px $primary;
            color: $primary !important;
            margin-left: 20px !important;
            mat-icon {
                color: $primary;
            }
        }

    }
}
nav {
    background-color: $primary;
    position: relative;
    z-index: 10;

    ul {
        list-style: none;
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        overflow: auto;
        scrollbar-width: thin;
        -webkit-overflow-scrolling: touch;

        &::-webkit-scrollbar {
          height: 4px;
          width: 4px;
          background: rgba(0, 0, 0, 0.5);
        }

        &::-webkit-scrollbar-thumb {
          background: #eee;
          -webkit-border-radius: 9999px;
        }

        &::-webkit-scrollbar-corner {
          background: #eee;
        }

        li {
            flex: 1 0 auto;

            a {
                font-weight: 700;
                color: $white;
                text-decoration: none;
                display: block;
                line-height: 40px;
                text-align: center;
                font-size: 0.9em;
                padding: 0 3px;
                white-space: nowrap;

                &:hover {
                    background-color: #000;
                    @include transition;
                }
            }
        }
    }
}
.logo-wrapper {
    flex: 1;
}

.mat-badge-warn .mat-badge-content {
    transform: scale(0.8) !important;
    background-color: $primary !important;
}
.only-mobile {
    display: none !important;
}

@media (max-width: 1440px) {
    nav ul li a {
        font-size: 0.8em;
    }
}

@media (max-width: 900px) {
    header {

        .logo-wrapper {
            width: 100px;
            margin: auto;
            text-align: center;
            img {
                width: 60px;
            }
            .logo-name {
                font-size: 1.2em;
                display: none;
            }

            .logo-holder {
              text-align: center;
              img {
                width: 40px;
              }
              span {
                display: inline-block;
                margin-top: 0.3rem;
                font-weight: bold !important;
              }
            }
        }
    }
    nav {
        display: none;
    }
    .user {
        right: 10px !important;
        top: 0 !important;
    }
    .user-name {
        z-index: 30;
        position: relative;
    }
    .user-details {
        .round {
            margin-left: 10px !important;
            margin-right: 5px !important;
        }
    }

    .no-mobile {
        display: none !important;
    }

    .only-mobile {
        display: inline-block !important ;
    }

    .active-icons {
        position: fixed;
        bottom: 0;
        z-index: 100;
        width: 100%;
        height: 60px;
        display: flex;
        background-color: #fff;
        left: 0;
        box-shadow: $shadow;
        span {
            flex-direction: column;
            display: flex;
            width: 100%;

            mat-icon,
            a {
                margin: auto;
            }
        }
    }

    .search-bar {

        left: 60px;
    }
    .mobile-cats mat-icon {color:#000 !important}
}
