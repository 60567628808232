/*colors*/
$white:#fff;
$primary:#FF1F35;
$accent:#000;
$body: #fff;
$grey:#727272;
$white:#fff;
$black: #000;
$silver: #EBF3FA;
.grey {background-color:$grey;}
.green {background-color: $primary;}
.white {background-color: $white;}
.white-text{color:$white}
.black-text{color:$black}
.silver {background-color: $silver;}
$menu-fix-color: #0F5BA3;
$project-font-family: 'Open Sans', sans-serif;
$project-font-size: 14px;
$project-font-width: 500;
$wraper-max: 1440px;
$wraper-min: 960px;
$wraper: 80%;
$fontsize: 1em;
$grid-gutter: 3%;
$mask-color: rgba(0, 0, 0, 0.7);
$main_bg: #F9F9F9;
$h1_color: #000;
$header-icons: #0F5BA3;
$badge-color: E30C19;


$aside-width:350px;
$aside-icons-color: #727272;
$aside-color: #fff;
$aside-node-hover: #F2FBF9;
$shadow: 0 0px 5px 1px rgba(0, 0, 0, 0.06);
$big-shadow: 0 0 25px 1px rgba(0,0,0,.06) !important;


/* pre colors*/
.white {background-color: $white;}
.white-text {color: $white}
.primary {background-color: $primary !important;}
.accent {background-color:$accent;}
.primary-text {color: $primary !important;}
.accent-text {color:$accent;}

@mixin transition {
  -o-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;



}

// $erp-accent: (
//     50 : #e8f6e9,
//     100 : #c5e9c8,
//     200 : #9fdaa4,
//     300 : #79cb80,
//     400 : #5cc064,
//     500 : #3fb549,
//     600 : #39ae42,
//     700 : #31a539,
//     800 : #299d31,
//     900 : #1b8d21,
//     A100 : #c6ffc9,
//     A200 : #93ff98,
//     A400 : #60ff67,
//     A700 : #47ff4f,
//     contrast: (
//         50 : #000000,
//         100 : #000000,
//         200 : #000000,
//         300 : #000000,
//         400 : #000000,
//         500 : #000000,
//         600 : #ffffff,
//         700 : #ffffff,
//         800 : #ffffff,
//         900 : #ffffff,
//         A100 : #000000,
//         A200 : #000000,
//         A400 : #000000,
//         A700 : #000000,
//     )
// );

// $erp-primary: (
//     50 : #e5e5e5,
//     100 : #bebfbf,
//     200 : #939494,
//     300 : #686969,
//     400 : #474949,
//     500 : #272929,
//     600 : #232424,
//     700 : #1d1f1f,
//     800 : #171919,
//     900 : #0e0f0f,
//     A100 : #3fb549,
//     A200 : #4cd858,
//     A400 : #4aef58,
//     A700 : #47ff57,
//     contrast: (
//         50 : #000000,
//         100 : #000000,
//         200 : #000000,
//         300 : #ffffff,
//         400 : #ffffff,
//         500 : #ffffff,
//         600 : #ffffff,
//         700 : #ffffff,
//         800 : #ffffff,
//         900 : #ffffff,
//         A100 : #000000,
//         A200 : #000000,
//         A400 : #000000,
//         A700 : #000000,
//     )
// );

// 900 : #042f77,
// $erp-primary: (
//     50 : #e2ebf4,
//     100 : #b7cee3,
//     200 : #87add1,
//     300 : #578cbf,
//     400 : #3374b1,
//     500 : #0f5ba3,
//     600 : #0d539b,
//     700 : #0b4991,
//     800 : #084088,
//     900 : #e00a16,
//     A100 : #a5c1ff,
//     A200 : #729dff,
//     A400 : #3f7aff,
//     A700 : #2568ff,
//     contrast: (
//         50 : #000000,
//         100 : #000000,
//         200 : #000000,
//         300 : #000000,
//         400 : #ffffff,
//         500 : #ffffff,
//         600 : #ffffff,
//         700 : #ffffff,
//         800 : #ffffff,
//         900 : #ffffff,
//         A100 : #000000,
//         A200 : #000000,
//         A400 : #ffffff,
//         A700 : #ffffff,
//     )
// );

// $erp-accent: (
//     50 : #fce2e3,
//     100 : #f7b6ba,
//     200 : #f1868c,
//     300 : #eb555e,
//     400 : #e7303c,
//     500 : #e30c19,
//     600 : #e00a16,
//     700 : #dc0812,
//     800 : #d8060e,
//     900 : #d00308,
//     A100 : #fff8f8,
//     A200 : #ffc5c6,
//     A400 : #ff9293,
//     A700 : #ff797a,
//     contrast: (
//         50 : #000000,
//         100 : #000000,
//         200 : #000000,
//         300 : #000000,
//         400 : #ffffff,
//         500 : #ffffff,
//         600 : #ffffff,
//         700 : #ffffff,
//         800 : #ffffff,
//         900 : #ffffff,
//         A100 : #000000,
//         A200 : #000000,
//         A400 : #000000,
//         A700 : #000000,
//     )
// );
