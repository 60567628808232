@import "Variables.scss";
@import "Mixins.scss";
@import "components/Normalize.scss";
@import "components/Margins.scss";
@import "components/Paddings.scss";
@import "components/Typography.scss";
@import "components/Grid.scss";
@import "components/Sizing.scss";
@import "components/Flex-layout.scss";
@import "components/Wraper.scss";
@import "components/Header.scss";
@import "components/Material.scss";
@-webkit-keyframes fadeinout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-rendering: optimizelegibility;
}

body,
html {
  width: 100%;
  height: 100%;
  font-family: $project-font-family;
  background-color: $body;
  font-size: $project-font-size;
  font-weight: $project-font-width;
}
body {
  padding-top: 85px;
  position: relative;
  -webkit-animation: fadeinout 0.3s ease-in 1 forwards;
  animation: fadeinout 0.3s ease-in 1 forwards;
}
.copyright {
  opacity: 0.7;
  font-size: 1em;
  font-size: 0.9em;
}
footer {
  font-size: 0.9em;
  ul {
    list-style: none;
    li {
      a {
        line-height: 1.5;
        text-decoration: none;
        opacity: 0.8;
      }
    }
  }
}

.item {
  width: 20%;
  float: left;
  padding: 20px;

  .bordered {
    box-shadow: $shadow;
    border-radius: 5px;
    position: relative;
    .img {
      img {
        border-radius: 5px 5px 0px 0px;
      }
    }

    .title {
      padding: 20px;
      display: block;
      height: 60px;
      text-decoration: none;
      font-size: 1.2em;
      font-weight: 700;
      text-overflow: ellipsis;
      overflow: hidden;
      margin-bottom: 10px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .cond {
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
  }
  .published {
    opacity: 0.7;
    font-size: 0.8em;
    padding-left: 20px;
    padding-right: 20px;
  }
  .price {
    font-size: 1.4em;
    font-weight: 700;
    padding: 20px;
    color: $primary;
  }
  button {
    position: absolute;
    right: 12px;
    bottom: 12px;
  }
}

mat-tab-body {
  margin-top: 20px;
}

@media (max-width: 900px) {
  body {
    padding-top: 130px;
  }
  .wraper {
    width: 100% !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }
  .item {
    width: 50%;
    padding: 7px;
  }
  .row.cats *[class*="col-"] {
    width: 45%;
    float: left;
    clear: none;
    margin-left: 2.5% !important;
    margin-right: 2.5%;
  }
  h1 {
    font-size: 1.6em !important;
  }
  .h600 {
    height: 50vh !important;
    .w500 {
      width: 80% !important;
    }
  }

  .box.maxw200,
  .box.maxw300,
  .box.maxw250 {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
  .box.maxw100,
  .box.maxw500,
  .box.maxw30 {
    width: 100%;
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .row .popular-cat {
    width: 46% !important;
    float: left !important;
    clear: none !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
    margin-bottom: 10px;
  }
  .today-posts {
    width: 100%;
    clear: left;
    margin-bottom: 45px;
    text-align: center;
  }
  .center-mobile {
    text-align: center;
  }
  mat-radio-button {
    margin-bottom: 10px;
  }
  .row .image-upload {
    width: 21% !important;
    float: left !important;
    clear: none !important;
    margin-left: 2% !important;
    margin-right: 2% !important;
    margin-bottom: 10px;
  }
  .mat-menu-panel {
    width: 240px !important;
    min-width: inherit !important;
    max-width: inherit !important;
  }
  .mat-menu-panel .row .col-50 {
    float: left !important;
    width: 50% !important;
    clear: none !important;
  }

  .mat-form-field.sort .mat-form-field-wrapper {
    margin: 0 !important;
    padding-bottom: 0 !important;
  }
  .mat-form-field.sort .mat-form-field-infix {
    padding: 0.5em 0 0.5em 0;
  }
  .mat-form-field.sort .mat-form-field-label {top:20px !important}
  .search-bar     .mat-form-field-wrapper {padding-bottom: 0 !important;}

  .mat-progress-bar {position: fixed !important; top:0px; left:0; width: 100%;z-index: 500;height: 2px;}
}
